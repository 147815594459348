.Header {
  // background-color: aqua !important;
  position: sticky;
  top: 0;
    z-index: 1000;
  .navbar-brand {
    margin-right: 200px;
  }

  .navbar-nav {
    margin-left: 300px; /* This pushes the nav items to the right */
  }

  .navbar {
    background-color: white !important;
    display: flex !important;
    justify-content: space-between !important;
  }
  .navbar-brand {
    img {
      height: 55px;
    }
  }

  .nav-link {
    color: black;
    font-size: 20px !important;
    font-weight: 600;
  }

  @media screen and (max-width: 600px) {
    .navbar-nav {
      margin-left: 0;
    }

    .navbar-brand {
      margin-right: 0;
    }
  }
}

.Hero {
  background-color: #e2f0d6;
  height: 85vh;
  display: flex;
  align-items: center;
  .hero-heading h1 {
    margin-top: 20px;
    font-weight: 700;
    width: 50rem;
    // font-size: 40px;
  }

  .hero-text {
    font-size: 20px;
    margin-top: 30px;
  }

  .hero-img {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hero-img img {
    height: 70vh;
    // text-align: center;
  }

  .button {
    background-color: black;
    // height: 50px;
    color: white;
    padding: 18px;
    font-weight: 500;
    border-radius: 25px;
  }

  @media screen and (max-width: 600px) {
    .hero-img {
      display: none;
    //   font-size: 10px;
    }

    .hero-heading h1{
        width: 18rem;
        // font-size: 25px;
    }

    .hero-text{
        font-size: 17px;
    }
  }
}

.About {
  margin-top: 30px;
  .about-heading {
    text-align: center;
    font-weight: 800 !important;
    font-size: 35px;
  }

  .about-left {
    margin-top: 50px;

    .mission {
      display: flex;
      margin-top: 50px;
      border: 1px solid gray;
      border-radius: 10px;
      box-shadow: 0 1px 3px #0000001f, 0 1px 2px #0000003d;
      padding: 10px;
      img {
        height: 50px;
        margin-right: 20px;
        background-color: #e2f0d6;
        border-radius: 50px;
        padding: 10px;
      }
    }

    .vision {
      display: flex;
      margin-top: 50px;
      border: 1px solid gray;
      border-radius: 10px;
      box-shadow: 0 1px 3px #0000001f, 0 1px 2px #0000003d;
      padding: 10px;
      img {
        height: 50px;
        margin-right: 20px;
        background-color: #e2f0d6;
        border-radius: 50px;
        padding: 10px;
      }
    }
  }

  .about-right {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    .about-img img {
      margin-top: 40px;
      margin-left: 50px;
      height: 90vh;
    }
    
  @media screen and (max-width: 600px) {
    .about-img img{
        height: 400px !important;
        margin-left: 0%;
        // width: max-content !important;
    }
  }
  }
}

.Services {
  margin-top: 50px;

  .service-heading {
    text-align: center;

    h1 {
      font-size: 40px;
      font-weight: 800;
    }

    p {
      font-size: 25px;
      // width: 300px; /* Uncomment if needed */
    }
    @media screen and (max-width: 600px) {
        .service-para{
            font-size: 15px !important;
        }
      }
  }

  .card {
    margin-top: 34px;
    width: 400px !important;
    position: relative; 
    overflow: hidden; 
    border: 1px solid #ddd;

    img {
      height: auto;
      width: 100%;
      transition: transform 0.5s ease;
      display: block;
    }

    &:hover img {
      transform: scale(1.1);
      transform-origin: center;
    } 
  }

  @media screen and (max-width: 600px) {
    .card{
        width: 350px !important;
    }
  }

  .card-body {
    text-align: center;
    background-color: #e2f0d6;

    .card-text {
      // background-color: #e2f0d6; /* Uncomment if needed */
      font-size: 25px;
      font-weight: 600;
    }
   
  }
}

.Work {
  margin-top: 50px;
  .work-heading {
    text-align: center;

    h1 {
      font-size: 40px;
      font-weight: 800;
    }

    p {
      font-size: 20px;
      // width: 300px; /* Uncomment if needed */
    }
  }

  .card {
    margin-top: 34px;
    width: 400px !important;
    background-size: contain !important;
    // width: max-content;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);

    img {
      height: 200px;
      // width: 300px;
      padding: 30px !important;
      background-size: contain !important;
      // width: 100%;
    }
  }

  @media screen and (max-width: 600px) {
    .card{
        width: 350px !important;
    }
  }

  .depot-card img {
    width: 200px !important;
    margin-left: 40px;
  }

  .card-body {
    text-align: center;

    .card-text {
      // background-color: #e2f0d6; /* Uncomment if needed */
      font-size: 20px;
      font-weight: 600;
    }
  }
}

.Contact {
  margin-top: 80px;
  .contact-left {
    h1 {
      font-size: 50px;
      font-weight: 700;
    }
    p {
      font-size: 20px;
      margin-top: 20px;
    }
  }

  .contact-right {
    .col input {
      padding: 15px;
    }

    .email {
      margin-top: 30px;
    }

    .message {
      padding-top: 30px;
    }

    .message textarea {
      height: 100px;
    }
  }
  .send {
    margin-top: 15px;
    padding: 10px;
    width: 100px;
    background-color: black;
    color: white;
    font-size: 20px;
    border: none;
    border-radius: 30px;
  }
  .send-message {
    display: flex;
    justify-content: end;
  }
}

// .Footer {
//     margin-bottom: 100px;
//     margin-top: 50px;

//     .footer-content {
//         display: flex;
//         justify-content: space-between; /* Space out the content horizontally */
//     }

//     .footer-logo img {
//         height: 120px;
//         /* Optional: add width if necessary */
//     }

//     .footer-items {
//         display: flex; /* Use flexbox for alignment */
//         width: 100%; /* Adjust as necessary */
//         justify-content: space-between; /* Space out sections horizontally */
//     }

//     h3{
//         font-size: 25px;
//     }

//     .footer-services,
//     .footer-time,
//     .services-area,
//     .contact-us {
//         /* Flexbox will handle vertical alignment */
//         flex: 1; /* Ensure each section takes equal space */
//     }

//     /* Ensure all lists have no bullets and proper styling */
//     ul {
//         list-style: none;
//         padding: 0;
//         margin: 0;
//     }

//     li {
//         margin-bottom: 10px; /* Optional: add space between list items */
//     }

//     a {
//         text-decoration: none;
//         color: black;
//         font-size: 20px;
//         font-weight: 600;
//     }
// }
.Footer {
    background-color: #e2f0d6;
    height: 50vh;  /* Ensures full page height */
    margin-top: 50px;
    padding-top: 100px;
    text-align: center;
    display: flex;  /* Flexbox to center the content */
    flex-direction: column;  /* Stacks the content vertically */
    justify-content: center;  /* Centers content vertically */
    align-items: center;  /* Centers content horizontally */
    overflow: hidden; /* Prevents overflow on small screens */
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    width: 100%;  /* Ensures the footer content is stretched across the full width */
    max-width: 1400px; /* You can adjust the max-width if needed */
  }
  
  .footer-logo img {
    height: 120px;
  }
  
  .footer-items {
    margin-left: 100px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Ensures the footer items are centered */
  }
  
  .footer-services, .footer-time, .services-area, .contact-us {
    margin-bottom: 20px; /* Adds spacing between sections */
  }
  
  .footer-services h3, .footer-time h3, .services-area h3 {
    padding-left: 30px;
  }
  
  ul li {
    list-style: none;
  }
  
  ul li a {
    text-decoration: none;
    color: black;
    font-size: 17px;
    font-weight: 600;
  }
  
  .contact-email {
    display: flex;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  @media screen and (max-width: 600px) {
    .Footer {
      height: 100vh;  /* Ensures full height on small screens */
      padding-top: 20px; /* Adjust padding for smaller screens */
        // margin-bottom: 10px;
    }
    
    .footer-logo {
      display: none; /* Hides the logo on small screens */
    }
    
    .footer-content {
      flex-direction: column; /* Stacks footer items vertically on small screens */
      align-items: center;
    }
    
    .footer-items {
      margin-left: 0; /* Removes left margin on small screens */
    }

    .footer-services h3, .footer-time h3, .services-area h3 {
        font-size: 14px;  /* Smaller heading text on small screens */
      }
    
      ul li a {
        font-size: 12px;  /* Smaller text size for links on small screens */
      }
     
      .contact-us ul {
        padding-left: 0;
      }
    
      .contact-us ul li a {
        font-size: 12px;  /* Further smaller text size for contact links on small screens */
      }

      .contact-us img {
        height: 15px;  /* Even smaller image size for mobile */
        // margi-bottom: 8px;  /* Adjust space below image */
      }
  }
  
